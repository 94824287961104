import logo from './logo.svg';
import './App.css';
import {decks} from './decks.js'
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// States: MAIN, DECK_VIEWING

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageState, setPageState] = useState("DECK_CHOOSING");
  // const [decksToUse, setDecksToUse] = useState(new Set());
  const [deckToUse, setDeckToUse] = useState("");
  const [currentLevel, setCurrentLevel] = useState("");
  const [currentCardNumber, setCurrentCardNumber] = useState(0);
  const [shuffleState, setShuffleState] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [rngSeed, setRngSeed] = useState(parseInt(urlParams.get('rngSeed')));

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const deck = urlParams.get('deck');
    const level = urlParams.get('level');
    const cardNumber = parseInt(urlParams.get('cardNumber'));
    const rngSeedParam = parseInt(urlParams.get('rngSeed'));
    const shuffleState = urlParams.get('shuffle') === "true";
    if (deckExists(deck) && deckContainsLevel(deck, level)
      && deckLevelCardExists(deck, level, cardNumber)) {
      setPageState("DECK_VIEWING");
      setDeckToUse(deck);
      setCurrentLevel(level);
      setCurrentCardNumber(cardNumber);
    }
    setShuffleState(shuffleState);
  }, []);

  useEffect(() => {
    setUrlDeckNameParam(deckToUse);
  }, [deckToUse]);

  useEffect(() => {
    setUrlCardNumberParam(currentCardNumber);
  }, [currentCardNumber]);

  useEffect(() => {
    setUrlLevelNameParam(currentLevel);
  }, [currentLevel]);

  useEffect(() => {
    setUrlParam('rngSeed', rngSeed);
  }, [rngSeed]);

  function mulberry32(a) {
    const rng = () => {
      var t = a += 0x6D2B79F5;
      t = Math.imul(t ^ t >>> 15, t | 1);
      t ^= t + Math.imul(t ^ t >>> 7, t | 61);
      return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }
    for (var i = 0; i < 15; i++) rng();
    return rng;
  }

  function getRandomInt(min, max, rng) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(rng() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  // console.log(decksToUse);

  // const toggleDeck = (deckName) => {if (decksToUse.has(deckName)) {
  //   setDecksToUse(decksToUseState => {decksToUseState.delete(deckName); return decksToUseState});
  //   // setDecksToUse(decksToUseState => decksToUseState.filter((eachDeck) => {return eachDeck !== deckName}));
  // } else {
  //   setDecksToUse(decksToUseState => decksToUseState.add(deckName));
  //   // setDecksToUse(decksToUseState => {decksToUseState.push(deckName); return decksToUseState});
  // }
  //   setDecksToUse(decksToUseState => new Set(decksToUseState)); // React will not rerender unless new reference is passed, so make copy here
  //   console.log(decksToUse);
  //   // console.log(decksToUse.has("MAIN"));
  // }

  const setDeck = (deckName) => {
    setDeckToUse(deckName);
  }

  const getDeck = (deckName) => {
    return decks.find(deck => deck.name === deckName);
  }

  const deckExists = (deckName) => {
    return getDeck(deckName) !== undefined;
  }

// console.log(decksToUse.has("MAIN"));

  const isDeckSelected = (deckName) => {
    // return decksToUse.has(deckName);
    return deckToUse === deckName;
  }

  const isCurrentLevelSelected = (level) => {
    return level === currentLevel;
  }

  const getDeckLevel = (deckName, levelName) => {
    const deck = getDeck(deckName);
    return deck.levels.find(level => level.name === levelName);
  }

  const deckContainsLevel = (deckName, levelName) => {
    const level = getDeckLevel(deckName, levelName);
    if (level === undefined) {
      return false;
    }
    return true;
  }

  const getDeckLevelCard = (deckName, levelName, cardNumber) => {
    const level = getDeckLevel(deckName, levelName);
    return level.questions.at(cardNumber);
  }

  const deckLevelCardExists = (deckName, levelName, cardNumber) => {
    const card = getDeckLevelCard(deckName, levelName, cardNumber);
    return card !== undefined;
  }

  const setUrlCardNumberParam = (cardNumber) => {
    setUrlParam("cardNumber", cardNumber);
  }

  const setUrlDeckNameParam = (deckName) => {
    setUrlParam("deck", deckName);
  }

  const setUrlLevelNameParam = (levelName) => {
    setUrlParam("level", levelName);
  }

  const setUrlParam = (paramName, paramValue) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set(paramName, paramValue);
    setSearchParams(updatedSearchParams.toString());
  }

  const goToDeckChoosingState = () => {
    setPageState("DECK_CHOOSING");
  }

  const goToDeckViewingState = () => {
    setCurrentCardNumber(0);
    setRngSeed(Math.floor(Math.random() * 100));
    setPageState("DECK_VIEWING");
  }

  const goToLevelChoosingState = () => {
    setPageState("LEVEL_CHOOSING");
  }

  const incrementCurrentCardNumber = () => {
    setCurrentCardNumber(currentCardNumberState => currentCardNumberState + 1)
  }

  const decrementCurrentCardNumber = () => {
    setCurrentCardNumber(currentCardNumberState => currentCardNumberState - 1)
  }

  const deckChoosingPage = () => {
    const deckList = decks.map(deck => {
      return (
        <div onClick={() => setDeck(deck.name)} className={isDeckSelected(deck.name) ? 'deckSelected' : 'deckUnselected'}>
          {deck.friendlyName}
        </div>
      )
    });
    console.log(deckList);
    console.log(deckToUse);
    return (
      <> 
      <div className={"PageTitle"}>Choose Deck:</div>
      {deckList}
      <div onClick={goToLevelChoosingState} className="NextButton">
      Next
      </div>
      </>
    );
  }

  const levelChoosingPage = () => {
    console.log(getDeck(deckToUse));
    const levelList = getDeck(deckToUse).levels.map(level => {
      return (
        <div onClick={() => setCurrentLevel(level.name)} className={isCurrentLevelSelected(level.name) ? 'deckSelected' : 'deckUnselected'}>
          {level.friendlyName}
        </div>
        );
      }
    );
    return (
      <>
        <div onClick={goToDeckChoosingState} className="BackButton">
          Back
        </div>
        <div className={"PageTitle"}>
          Choose level:
        </div>
        {levelList}
        <div onClick={goToDeckViewingState} className="NextButton">
        Next
        </div>
      </>
    )
  }

  const deckViewingPage = () => {
    // const cards = [];
    // decksToUse.forEach((deckName) => {
    //   cards.push(...DECKS.get(deckName).get(currentLevel))
    // });

    const cards = getDeck(deckToUse).levels.find(level => level.name === currentLevel).questions;
    let cardToDisplay;
    if (shuffleState) {
      const chosenCards = []
      const rng = mulberry32(rngSeed);
      while (chosenCards.length < Math.min(currentCardNumber + 1, cards.length)) {
        const randomInt = getRandomInt(0, cards.length, rng);
        if (!chosenCards.includes(randomInt)) {
          chosenCards.push(randomInt);
        }
      }
      const nextRandomInt = getRandomInt(0, cards.length, rng);
      console.log(chosenCards, nextRandomInt);
      cardToDisplay = cards[chosenCards.pop()];
    } else {
      cardToDisplay = cards[currentCardNumber];
    }

    return (
      <>
      <div onClick={goToLevelChoosingState} className="BackButton">
          Back
      </div>
      <div className="CardContent">
        {cardToDisplay}
      </div>
      <div>
        <span onClick={decrementCurrentCardNumber} className="NextCardButton">Prev</span>
        <span onClick={incrementCurrentCardNumber} className="PrevCardButton">Next</span>
      </div>
      <div className="CardCount">
      Card #{currentCardNumber + 1} out of {cards.length}
      </div>
      </>
    )

  }

  return (
    <div className="App">
      <header className="App-header">
        {pageState === "DECK_CHOOSING" ? deckChoosingPage() : ""}
        {pageState === "LEVEL_CHOOSING" ? levelChoosingPage() : ""}
        {pageState === "DECK_VIEWING" ? deckViewingPage() : ""}
        <p>
          
        </p>
      </header>
    </div>
  );
}

export default App;

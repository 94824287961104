const createNewDeck = (deckUniqueName, deckFriendlyName, questionLevels) => {
	return {
		name: deckUniqueName,
		friendlyName: deckFriendlyName,
		levels: questionLevels,
	}
}

const createQuestionLevel = (levelName, levelFriendlyName, questions) => {
	return {
		name: levelName,
		friendlyName: levelFriendlyName,
		questions: questions,
	}
}

const mainWnrsQuestionLevels = [ 
	createQuestionLevel("ONE", "Level 1 (Perception)", [
			"What was your first impression of me?",
		    "What do you think is the hardest part of what I do for a living",
		    "What reality show do you think I'm most likely to binge watch? explain.",
		    "Do I seem like a cat or dog person?",
		    "What do my shoes tell you about me?",
		    "Do I seem like more of a creative or analytical type? explain.",
		    "How many speeding tickets do you think I've gotten in my life?",
		    "Do I seem like someone who would get a name tattooed on myself?",
		    "What do you think I'm most likely to splurge on?",
		    "Finish the sentence: just by looking at you I'd think ____.",
		    "What character would I play in a movie?",
		    "Do I remind you of anyone?",
		    "Who do you think my celebrity crush is?",
		    "Do you think I've ever checked an ex's phone for evidence?",
		    "What's the first thing you noticed about me?",
		    "Do I seem like a coffee or tea person? Sweetened or unsweetened?",
		    "What do you think my go to karaoke song is?",
		    "If you were to buy me a present, knowing nothing about me other than what I look like, what would it be?",
		    "What does my phone wallpaper tell you about me?",
		    "Do I look kind? explain.",
		    "Do you think I fall in love easily? why or why not?",
		    "As a child, what do you think I wanted to be?",
		    "Do you think I'm usually early, on time, or late to events? explain.",
		    "What subject do you think I thrived in at school? Did I fail any?",
		    "What compliment do you think I hear the most?",
		    "Do I seem like a morning person or a night owl? why?",
		    "What fast food restaurant do you think I'm most likely to drive through? What's my order?",
		    "What is my body language telling you right now?",
		    "What does my instagram tell you about me?",
		    "Do you think plants thrive or die in my care? explain.",
		    "On a scale of 1-10, how messy do you think my car is? 1 being clean 10 complete disaster",
		    "Do you think I was popular in school? explain.",
		    "Do you think I've ever been fired from a job? If so, what for?",
		    "Do you think I intimidate others? why or why not?",
		    "How likely am I to go camping? how high maintenance is my set up?",
		    "What about me is most strange or unfamiliar to you?",
		    "If myspace were still a thing, what would my profile song be?",
		    "What about me intrigues you?",
		    "What does my style tell you about me?",
		    "Do you think I've ever had my heart broken?",
		    "Do you think I've been in love?",
		    "What is the worst assumption someone has made about you?",
	    ]
	), 
	createQuestionLevel("TWO", "Level 2 (Connection)", [
			"What is your defining characteristic?",
		    "What's the most pain you've ever been in that wasn't physical?",
		    "How are you, really?",
		    "What is the last thing your mom has lied to you about?",
		    "What's your father's name and one thing about him?",
		    "What have I tolerated from people in the past that I no longer have space for?",
		    "Something that brought a smile to my face this week was ___.",
		    "What are you feeling a lot of lately?",
		    "What do you need right now but aren't communicating?",
		    "What are you currently working through that others don't see?",
		    "What was the biggest turning point in your dating life?",
		    "What is your heart telling you?",
		    "What is your most toxic trait you can admit to?",
		    "Who in your life deserves the biggest thank you? Let them know if you can.",
		    "What are you overthinking right now?",
		    "What did you last relationship teach you about yourself?",
		    "What belief about yourself no longer serves you?",
		    "What do you need to forgive yourself for?",
		    "What's the hardest lesson you've learned in love?",
		    "You hurt me, but thank you for ___.",
		    "Who in your life can you be your most vulnerable with?",
		    "Which one of your parent's personality traits do you want to keep/let go of?",
		    "Dear younger self: ____.",
		    "Think of someone you admire. Why did this person come to mind?",
		    "Are you lying to yourself about anything?",
		    "What's your favorite part of yourself that isn't physical?",
		    "What's the hardest part about dating you?",
		    "I feel loved when ___.",
		    "What is something you've learned about yourself this week?",
		    "Self love is ___.",
		    "What was the lesson in your most recent painful experience?",
		    "I know I'm in love when ___.",
		    "What's your favorite song lyric you can think of off the top of your head?",
		    "How did you get over your first love?",
		    "What's the best lesson an ex has ever taught you?",
		    "Is there anyone who's changed your life but doesn't know it?",
		    "What do you need right now, more than anything?",
		    "When was the last time you felt lucky to be you?",
		    "What do you need to accept today?",
		    "When was the last time you felt most yourself?",
		    "What's your mother's name and the most valuable lesson she's taught you thus far?",
		    "What are you most grateful for in this current moment?",
		    "What are you still trying to prove to yourself?",
		    "What have you been taking for granted lately?",
		    "Is there a feeling you miss?",
		    "What would your younger self not believe about your life today?",
		    "If you could have it your way: Who would you be with? Where would you be? And what would you be doing?",
		    "What do you need to hear right now?",
		    "What title would you give this chapter in your life?",
		    "What dating advice would you give your younger self?",
		    "What lesson should you have learned by now?",
		    "When was the last time you were able to use your pain to help someone else?",
		    "What would you never want to change about yourself?",
		    "What question did this year leave you with?",
		    "What about yourself is hardest to admit?",
		    "What question are you trying to answer most in your life right now?",
		    "Finish the sentences: strangers would describe me as _____. Only I know that I am ______",
		    "When you're asked how are you, how often do you answer truthfully?",
		    "Has a stranger ever changed your life?",
		    "What is the most unexplainable thing that's ever happened to you?",
		    "Describe your perfect day!",
		    "Have you changed your mind about anything recently?",
		    "What's been the best compliment a stranger has ever given you?",
		    "What is a compliment you wish you received more frequently?",
		    "What do you crave more of?",
		    "What has been your earliest recollection of happiness?",
		    "What are you more afraid of, failure or success? And why?",
		    "How would you describe the feeling of being in love in one word?",
		    "When was the last time you surprised yourself?",
		    "What's been your happiest memory this past year?",
		    "Do you think the image you have of yourself matches the image people see you as?",
		    "If you have, when was the moment you realized you weren't invincible?",
		    "What lesson took you the longest to unlearn?",
		    "What part of your life works? What part of your life hurts?",
		    "What is your 1st love's name and the reason you fell in love with him/her?",
		    "What's your mother's name? And the most beautiful thing about her?",
		    "If you could get to know someone in your life on a deeper level, who would it be and why?",
		    "Are you missing anyone right now? Do you think they are missing you too?",
		    "What is a dream you've let go of?",
		    "What is the last thing you lied to your mother about?",
		    "Have you ever told someone I love you but didn't mean it? if so, why?",
		    "How can you become a better person?",
	    ]
	),
	createQuestionLevel("THREE", "Level 3 (Reflection)", [
			"What about me surprised you?",
		    "What do you think my strength is?",
		    "Based on what you've learned about me, do you have any Netflix recommendations?",
		    "What is one thing you think I can do that would dramatically improve my life?",
		    "What about me is hardest for you to understand?",
		    "Who do I remind you of?",
		    "What can you be kinder towards yourself for today?",
		    "What's one small thing you can do to be a better person this year?",
		    "What can we create together?",
		    "How would you describe me to a stranger?",
		    "In a word, how would you describe our conversation?",
		    "What about me most surprised you?",
		    "What would be the perfect gift for me?",
		    "What do you think my weakness is?",
		    "Why do you think we met?",
		    "What do you admire most about me?",
		    "Based on what you learned about me, what book would you recommend I read?",
		    "What can I teach you?",
		    "What am I most qualified to give advice about?",
		    "What can I help you with?",
		    "If we were in a band, what would our name be?",
		    "Based on what you learned about me, does my social media accurately reflect who I am? Why or why not?",
		    "What parts of yourself do you see in me?",
		    "What do I need to hear right now?",
		    "Based on what you know about me, do you have any netflix recommendations?",
		    "What do you think my superpower is?",
		    "What question were you most afraid to answer?",
		    "What answer of mine made you light up?",
		    "What is a lesson you will take away from our conversation?",
		    "When in this game did you feel most connected to me?",
		    "How does one earn your vulnerability? Have I earned it? How can I earn more?",
		    "What do you think I should know about myself that perhaps I'm unaware of?",
		    "What do you recommend I let go of, if anything?",
		    "What do you think our most important similarity is?",
		    "In one word, how would you describe our conversation?",
		    "When this game is over, what will you remember about me?",
		    "How do our personalities complement each other?",
		    "What would make you feel closer to me?",
		    "What do you think I fear the most?",
		    "What has this conversation taught you about yourself?",
		    "What do you think my defining characterisitic is?",
		    "Do you believe everyone has a calling? If so, do you think I've found mine?",
		    "In one word, describe how you feel right now",
	    ]
	),
];

const mainWnrsDeck = createNewDeck("MAIN_WNRS", "Main WNRS", mainWnrsQuestionLevels);

const honestDatingWnrsQuestionLevels = [ 
	createQuestionLevel("ONE", "Level 1 (Perception)", [
		    "How many relationships do you think I've been in? How many times do you think I've been in love? Explain.",
		    "Just by looking at me, what would you think I do for a living?",
		    "What was the first thing you noticed about me when we met in person?",
		    "What do you think my type is? Describe in detail.",
		    "What do you think I was like in high school? Explain.",
		    "What do you think my drink of choice is?",
	    	"Do you think I'm still on good terms with my exes? Why or why not?",
	    ]
	), 
	createQuestionLevel("TWO", "Level 2 (Connection)", [
		    "When's the last time you ghosted someone and why?",
		    "What about me intrigued you besides my physical appearance?",
		    "What do your friends know about me, if anything?",
		    "What part of your job energizes you the most? Leaves you the most drained?",
		    "Why did you break up with your ex?",
		    "What are you feeling a lot of lately?",
		    "Do you have any unconventional opinions when it comes to romantic relationships",
		    "I know I really like someone when ____",
		    "Has sex ever been a deal breaker in your past relationships? Explain.",
		    "How has your view on love evolved over time, if at all?",
		    "Describe your worst heartbreak. What did it teach you?",
		    "Who in your life do you feel most yourself around? Why?",
		    "What is something new you've learned about yourself recently?",
		    "How do you define dating?",
		    "Have you ever considered an open relationship? Why or why not?",
		    "Which one of your parents' personality traits do you want to keep? Let go of?", 
		    "What's the hardest part about dating you?",
		    "What dating advice would you give your younger self?",
		    "What was the worst date you've ever been on and why?",
		    "What are you overthinking right now?",
		    "What have you tolerated from others in the past that you no longer have space for?",
		    "How emotionally available do you currently feel? Explain.",
		    "What has being single taught you about yourself?",
		    "What abbout you has felt repeatedly misunderstood by others, if anything?",
		    "what's a non-negotiable in your life?",
		    "What did the people who raised you teach you about love? How has that shaped your views on love today?",
	    ]
	),
	createQuestionLevel("THREE", "Level 3 (Reflection)", [
		    "What song should I listen to on my way home?",
		    "Based on what you've learned about me, what do you think I'm looking for romantically?",
		    "What mannerisms of mine stood out the most to you, if any?",
		    "If you were to write my dating profile bio, what would it say?",
		    "On a scale of 1-10, how emotionally available do I seem? Explain.",
		    "How would you describe our date in one word?",
		    "What part of my personality isn't showcased online?",
		    "What do you find most attractive about me that isn't physical?"
	    ]
	),
];

const honestDatingWnrsDeck = createNewDeck("HONEST_DATING_WNRS", "Honest Dating WNRS", honestDatingWnrsQuestionLevels);

const relationshipWnrsQuestionLevels = [ 
	createQuestionLevel("ONE", "Level 1 (Perception)", [
			"What assumption did you make about me that turned out to be right?",
			"What about me first stood out to you physically?",
			"What was I wearing on our first date?",
			"How would you describe our first date in one word?",
			"Did you ever stalk my social media before we met? If so, what stood out?",
			"What's my go-to delivery order at the moment?",
			"How important do you feel physical affection is for me? Explain.",
			"What did your friends think about me when we first started dating?",
			"How important do you think gifts are to me? Explain.",
			"What do you think I'd like to do more often with you?",
			"What do you think I'm most sensitive to?",
			"What do you think my view on marriage is? Explain.",
			"What did you think I'd be like in bed? How accurate did that turn out to be?",
			"What about me most in to do before we started dating?",
			"What was the hardest thing for you to understand about me early on?",
			"What do you think is the greatest source of stress for me right now?",
			"What intimidated you most about me early on, if anything?",
			"What warning would you give yourself about me at the start of our relationship?",
			"What do you think my biggest goal for this year is?",
			"What do you think is the main way I show/give love?",
			"What's my biggest pet peeve? Explain.",
			"How would you describe my relationship with work in one word? Explain.",
			"What's your favorite thing to see me wear? (Nothing doesn't count.)",
			"What's my coffee order? Get specific",
			"What did you think my type was before we met?",
			"What did my friend group reveal to you about me?",
			"Describe the first time we met through your perspective in detail",
			"What would I most likely be fired for?",
			"What do I dislike that most people like?",
			"What do I love that most people dislike?",
			"How important do you think birthdays and holidays are to me? Explain.",
			"Who do you think was more nervous on our first date? Explain.",
			"If our relationship was a Netflix series, what would it be called? What episode are we on?",
			"What do you think my perfect date night would be? Describe in detail.",
			"What was the first thing you noticed about me that wasn't physical?",
			"What assumption did you make about me that turned out to be false?",
			"On a scale of 1-10, how open do you feel I am with you? Explain.",
			"What would a day of complete spoiling me look like?",
			"Did you make any assumptions abbout me based on my age? If so, what were they?",
			"What do you think I'm most sensitive to during a fight or disagreement?",
			"What celebrity couple are we msot like?",
			"If we weren't in a relationship, what pick up line would you use with me?",
			"Do you think I have any unconventional views on love?",
		    "If our first kiss was a song, what would it be called?",
		    "How do I show you I'm upset without telling you I'm upset?",
	    ]
	), 
	createQuestionLevel("TWO", "Level 2 (Connection)", [
			"What's one small thing I do that tells you everything you need to know about me?",
			"What fight of ours did you struggle with the most? What felt particularly hard for you about it?",
			"What title would you give this chapter of our sex life?",
			"In what way(s) is this relationship unlike any other before?",
			"What's one way our relationship has gotten better over time?",
			"What's something we used to do at the beginning of our relationship that you miss the most?",
			"What's the hardest compromise you've had to make in this relationship?",
			"How have I changed you for the better?",
			"How have I changed you for the better?",
			"How has your definition of love changed over time, if at all?",
			"What compromise have you seen me make in our relationship that you're most grateful for?",
			"What's the sexiest thing I do without realizing it?",
			"When was the last time someone betrayed your trust? How did that affect your ability to trust today?",
			"Finish the sentences: I knew I really liked you when ____. I knew I loved you when ____.",
			"What did you learn to not take personally with me?",
			"What do you overthink most in our relationship, if anything?",
			"What can we laugh at now that once felt so daunting in our relationship?",
			"The hardest thing for me to reveal about myself to you was ____.",
			"What has this relationship taught you about yourself?",
			"What's the most romantic thing I've done for you recently?",
			"What did our worst argument teach you?",
			"What are you currently working through that I don't see, if anything?",
			"How do you think my job affects our relationship?",
			"What have you been extra sensitive to lately?",
			"What feelings are hard for you to communicate to me? How can I make it easier?",
			"What immediately attracted you to me? What attracted you more over time?",
			"What's the best gift I've given you? Material and immaterial?",
			"What about you feels hardest to love?",
			"What's the most important lesson a past relationship has taught you that you applied to ours?",
			"Are there any insecurities from previous relationships that you carried into this one? If so, what are they?",
			"What recent experience made you feel closer to me?",
			"How do our strengths and weaknesses compliment each other?",
			"What have I help to appreciate about yourself?",
			"How do I show that I love you without telling you?",
			"When was the last time I hurt you, perhaps unintentionally?",
			"How have you seen me grow in this relationship?",
			"Have I changed your worldview in any big or small way?",
			"How does our age difference, or lack thereof, affect us? If at all?",
			"What's one small way we can improve our sex life?",
			"How do you define cheating?",
			"What small thing had a big impact on our relationship?",
			"How does social media affect me in my day-to-day life? How does that affect our relationship?",
			"Have I ever betrayed your trust in any big or small way? What would help rebuild that trust?",
			"What did you learn from your parents relationship that you'd like to apply/avoiding this one?",
		    "When do you think I'm at my best? Who am I with? What am I doing?",
	    ]
	),
	createQuestionLevel("THREE", "Level 3 (Reflection)", [
			"What's a super power of mine that perhaps I'm unaware of?",
			"Finish the sentence: I'm proudest of you for _____.",
			"When was the last time you felt lucky to be my significant other? Get specific. 'All the time' doesn't count.",
			"What could other couples learn from us?",
			"What's one thing you'd never change about our relationship?",
			"What do we have in this relationship that you never thought was possible?",
			"What most excites you about our future?",
			"If we started a business together what would it be?",
			"In your opinion, how am I holding myself back the most?",
			"What would you tell yourself on our first date if you had the chance?",
			"What do you think our defining characteristic is as a couple?",
			"what's your favorite ritual of ours? What's a ritual you'd like to create for us moving forward?",
			"What am I most qualified to give relationship advice about?",
			"How have I helped you become a better person?",
			"When in this game did you feel most connected to me?",
			"If you could prescribe a relationship one thing, what would it be and why?",
			"What answer of mine surprised you the most throughout this game?",
			"Is there anything you still don't know about me that you wish you did?",
			"What goal would you feel best for you to accomplish this year? How can I support you in that?",
			"What don't I give myself enough credit for?",
			"What about our relationship are you proudest of?",
			"What did this conversation teach you about our relationship? What did it teach you about yourself?",
			"Thank you for helping me realize _____ about myself.",
			"What is a lesson about love that you'll take away from this game?",
			"What question felt most vulnerable for you to answer?",
			"What question felt most vulnerable for you to ask? Explain.",
			"What answer of mine will you still be thinking about when this game is over?",
			"What do you find difficult to open up about me, if anything?",
			"What do you think I struggle to open up about in our relationship?",
			"What's one thing you'll do differently during our next argument?",
			"What part of my heart do you wish you could heal?",
			"How would you describe the way I love you to a stranger?",
			"What's one difference between us that you love?",
			"What's one similarity between us that you love?",
			"On a scale of 1-10, how vulnerable do you think I've been in this game? Explain.",
			"How have I changed since we first met? What hasn't changed about me at all?",
			"What some thing I'm too hard on myself about?",
			"What weakness of yours do I help complement the most?",
			"What do you wish we did more of?",
		    "Finish the sentence: thank you for accepting _____ about me.",
	    ]
	),
];

const relationshipWnrsDeck = createNewDeck("RELATIONSHIP_WNRS", "Relationship WNRS", relationshipWnrsQuestionLevels);

const breakupWnrsQuestionLevels = [
	createQuestionLevel("ONE", "Level 1", [
		"WHAT’S YOUR EX’S NAME AND ONE THING ABOUT THEM?",
		"WHAT WERE YOU OVER-RATIONALIZING THROUGHOUT YOUR LAST RELATIONSHIP, IF ANYTHING?",
		"WHAT WOULD FEEL BEST TO FULLY ACCEPT IN THIS CURRENT MOMENT?",
		"REMINDER: BREAKUPS SKEW YOUR MEMORY. YOUR EX WAS NOT PERFECT. YOU WILL FEEL BETTER.",
		"HOW DID YOU GET OVER YOUR FIRST LOVE?",
		"ARE YOU TALKING TO OTHER PEOPLE CURRENTLY? IF SO, ARE YOU SEEKING A REAL CONNECTION OR NEEDING A DISTRACTION?",
		"WERE THERE ANY PATTERNS IN YOUR PREVIOUS RELATIONSHIP THAT YOU’RE NEWLY AWARE OF? IF SO, WHAT WERE THEY?",
		"DID YOUR LAST RELATIONSHIP REVEAL SOME TOXIC TRAITS IN YOURSELF THAT YOU’D LIKE TO WORK ON? IF SO, WHAT ARE THEY?",
		"WHAT CAN YOU DO FOR YOURSELF TODAY, EVEN IF YOU DON’T ENJOY IT, TO MAKE FUTURE YOU HAPPY?",
		"WHAT WAS THE BIGGEST TURNING POINT IN YOUR DATING LIFE?",
		"WHAT PART OF YOUR LAST RELATIONSHIP WORKED? WHAT PART OF IT HURT?",
		"WHO IN YOUR LIFE HAS BEEN TREATING YOU RIGHT LATELY? LET THEM KNOW BEFORE MOVING TO THE NEXT CARD. (EXES NOT INCLUDED.)",
		"WHAT HAS HELPED YOU HEAL FROM A HEARTBREAK IN THE PAST, BESIDES TIME AND MEETING SOMEONE NEW?",
		"AFTER YOUR MOST RECENT BREAKUP, WHAT SONG DID YOU PLAY ON REPEAT?",
		"WHAT ARE 3 THINGS YOU CAN APPRECIATE ABOUT BEING SINGLE (EVEN IF IT FEELS HARD)?",
		"WHAT PARTS OF YOURSELF DO YOU NEED TO BREAK UP WITH?",
		"WERE YOU MORE ATTRACTED TO THEIR POTENTIAL OR WHO THEY REALLY WERE? EXPLAIN.",
		"DID THEIR LOVE LEAVE YOU FEELING GOOD MOST OF THE TIME? IF NOT, WHAT WAS THE DOMINANT FEELING THEY LEFT YOU WITH?",
		"DO YOU HAVE A LIMITING BELIEF ABOUT LOVE? WHERE DO YOU THINK IT STEMS FROM? HOW CAN YOU CHALLENGE THIS BELIEF?",
		"REMINDER: YOUR LOVE LIFE IS ONE AREA OF YOUR LIFE. DON’T FORGET TO NURTURE THE REST.",
		"WHAT VALIDATION ARE YOU SEEKING FROM A PARTNER? HOW CAN YOU GIVE IT TO YOURSELF?",
		"REMINDER: DON’T LET YOUR LONELINESS TAKE YOU BACK TO PEOPLE WHO REPEATEDLY HURT YOU.",
		"SIGNIFICANT OTHER ASIDE, WHEN YOU VISUALISE COMING HOME TO A LIFE YOU LOVE, WHAT DOES THAT LOOK LIKE? GET SPECIFIC.",
		"PICTURE YOU’RE TALKING TO YOUR EX. FINISH THE SENTENCE: YOU HURT ME, BUT THANK YOU FOR ________.",
		"WHAT DID YOUR LAST RELATIONSHIP MAKE YOU APPRECIATE ABOUT YOURSELF?",
		"WHAT STORY ABOUT LOVE HAVE YOU BEEN TELLING YOURSELF THAT NO LONGER SERVES YOU? HOW WOULD YOU LIKE TO CHANGE THAT STORY?",
		"HOW CAN YOU SHOW UP FOR YOURSELF WHEN NO ONE ELSE CAN?",
		"WHAT TITLE WOULD YOU GIVE THIS CHAPTER OF YOUR HEALING PROCESS?",
		"WHAT CAN YOU BE DOING NOW WITH ALL THE ENERGY YOU WERE PUTTING INTO THEM?",
		"WOULD YOU ENCOURAGE YOUR FUTURE CHILD TO BE IN A RELATIONSHIP LIKE YOUR LAST? WHY OR WHY NOT?",
		"WRITE A FINAL NOTE TO YOUR EX THAT THEY’LL NEVER SEE. GET IT ALL OFF YOUR CHEST. TEAR WHEN YOU FEEL READY.",
		"WHAT HAVE ALL YOUR PREVIOUS RELATIONSHIPS HAD IN COMMON THAT YOU’D LIKE TO AVOID MOVING FORWARD, IF ANYTHING?",
		"DO YOU WANT CLOSURE OR JUST AN EXCUSE TO TALK TO THEM? EXPLAIN.",
		"WHAT’S THE BEST LESSON AN EX HAS EVER TAUGHT YOU?",
		"WERE YOU LYING TO YOURSELF ABOUT ANYTHING DURING YOUR LAST RELATIONSHIP? IF SO, WHAT WAS IT?",
		"WHAT IS YOUR HEART TELLING YOU? WHAT IS YOUR MIND TELLING YOU?",
		"IMAGINE YOU’RE TALKING TO YOUR EX. FINISH THE SENTENCE: I’M SORRY FOR ________.",
		"HOW HAS YOUR LAST RELATIONSHIP PREPARED YOU FOR YOUR NEXT ONE?",
		"WHAT DO YOU WANT TO RECEIVE FROM A FUTURE RELATIONSHIP? WHAT DO YOU WANT TO GIVE? GET SPECIFIC.",
	]),
	createQuestionLevel("WILDCARDS", "wildcards", [
		"WILDCARD: WRITE DOWN 3 THINGS YOU LOVE ABOUT YOURSELF PHYSICALLY. PERMISSION TO BRAG.",
		"WILDCARD: WRITE DOWN 3 THINGS YOU WOULDN’T CHANGE ABOUT THIS CURRENT MOMENT.",
		"WILDCARD: SET A TIME THIS WEEK TO DO SOMETHING YOU LOVE AND LIMIT ANY DISTRACTIONS.",
		"WILDCARD: DRAW HOW YOUR HEART FEELS TODAY.",
		"WILDCARD: WRITE DOWN 3 THINGS ABOUT YOUR LAST RELATIONSHIP THAT YOU WILL NO LONGER ACCEPT MOVING FORWARD. GET SPECIFIC.",
		"WILDCARD: WRITE DOWN 3 THINGS YOU LOVE ABOUT YOURSELF THAT AREN’T PHYSICAL.",
		"WILDCARD: WRITE A STAND UP ROUTINE ABOUT YOUR LAST RELATIONSHIP. (1 MINUTE). READ OUT LOUD.",
		"WILDCARD: WRITE A NOTE TO YOUR FUTURE SELF. FOLD AND PLACE SOMEWHERE SAFE. SET A REMINDER TO OPEN 3 MONTHS FROM TODAY.",
	]),
];
const breakupWnrsDeck = createNewDeck("BREAKUP_WNRS", "Breakup Edition WNRS", breakupWnrsQuestionLevels);


const selfReflectionWnrsQuestionLevels = [
	createQuestionLevel("ONE", "Level 1", [
		"WHAT'S A NON-NEGOTIABLE IN MY LIFE?",
		"WHO DO I ENVY? WHY DO I ENVY THEM?",
		"WHICH ONE OF MY MISTAKES TAUGHT ME THE MOST THIS PAST YEAR? WHAT DID IT TEACH ME?",
		"WHAT AM I WEARING WHEN I HAVE THE BEST TIME? WHAT DO I ALWAYS REGRET WEARING?",
		"WHEN DO I FEEL MOST PRODUCTIVE? WHAT TIME IS IT USUALLY? AM I ALONE OR WITH OTHERS? (GET SPECIFIC.)",
		"WHAT'S THE MOST EXCITING IMPROVEMENT I'VE BEEN SEEING IN MYSELF RECENTLY?",
		"WHAT OR WHO DO I HAVE AN UNHEALTHY RELATIONSHIP WITH?",
		"WHAT HAVE I OUTGROWN RECENTLY? WHAT AM I GROWING INTO?",
		"WHAT HAVE I TOLERATED FROM PEOPLE IN THE PAST THAT I NO LONGER HAVE SPACE FOR?",
		"WHAT’S BECOME MORE IMPORTANT TO ME RECENTLY THAN EVER BEFORE?",
		"WHO DO I FEEL MOST MYSELF AROUND? WHY?",
		"WHAT PURCHASE HAS MEANT THE MOST TO ME RECENTLY? WHAT IS A PURCHASE THAT I COULD HAVE DONE WITHOUT?",
		"WHEN DO I FEEL MOST HELPFUL TO OTHERS?",
		"WHAT DO I KEEP DOING THAT KEEPS HURTING? WHY DO I KEEP REPEATING THIS BEHAVIOR?",
		"WHEN HAVE I GIVEN TOO MUCH OF MYSELF IN A RELATIONSHIP? WHAT DID THAT LOOK LIKE? WHAT LESSON DID THAT LEAVE ME WITH?",
		"HOW WOULD I DESCRIBE MY RELATIONSHIP WITH MYSELF IN ONE WORD?",
		"WHAT HAVE MY PAST RELATIONSHIPS TAUGHT ME ABOUT MYSELF? GOOD AND BAD?",
		"WHAT INSECURITY HAS BEEN HOLDING ME BACK THE MOST?",
		"WHEN WAS THE LAST TIME I TRULY ENJOYED MY OWN COMPANY? WHAT WAS I DOING?",
		"WHAT’S MY MOST TOXIC TRAIT I CAN ADMIT TO? WHERE DO I THINK IT STEMS FROM?",
		"WHAT IS THE MOST FUN I CAN REMEMBER HAVING RECENTLY? WHAT WAS I DOING? WHO WAS I WITH?",
		"WHAT DO I NEED HELP WITH CURRENTLY? WHO CAN HELP ME? HOW CAN I HELP MYSELF?",
		"WHEN WAS THE LAST TIME I REMEMBER BEING MEAN TO SOMEONE? WHY DID I ACT THIS WAY?",
		"WHAT HAVE I ACCOMPLISHED RECENTLY THAT WOULD HAVE SHOCKED ME A YEAR AGO?",
		"WHAT EXPECTATION HAVE I SET ON SOMEONE THAT HAS BEEN HURTING ME THE MOST?",
		"WHOSE LIFE SEEMS MOST FULFILLING TO ME? WHAT DOES THEIR LIFE LOOK LIKE?",
		"WHAT DID THE PEOPLE WHO RAISED ME TEACH ME ABOUT LOVE? WHAT LESSONS DO I WANT TO KEEP? WHAT DO I WANT TO CHALLENGE?",
		"WHAT IS AN UNPOPULAR OPINION OF MINE THAT I HESITATE TO SHARE WITH OTHERS?",
		"WHAT DO I DISLIKE THAT MOST PEOPLE LOVE? WHAT DO I LOVE THAT MOST PEOPLE DISLIKE?",
		"WHAT'S THE BEST GIFT I CAN GIVE MYSELF IN THIS CHAPTER OF MY LIFE?",
		"WHAT’S SOMETHING MEAN THAT WAS SAID TO ME IN MY CHILDHOOD THAT I CARRY TO THIS DAY? WHAT WOULD I SAY TO MY YOUNGER SELF NOW?",
		"ARE ANY OF MY INSECURITIES HURTING ANYONE ELSE BY ACCIDENT?",
		"WHAT DO I HAVE TO OFFER IN A ROMANTIC RELATIONSHIP? WHAT DO I WANT TO RECEIVE?",
		"WHAT'S AN IMPORTANT BOUNDARY I WANT TO SET WITH SOMEONE IN MY LIFE? WHY HAVEN'T I SET IT ALREADY?",
		"IN WHAT WAYS DO I WANT TO BE LIKE THE PEOPLE THAT RAISED ME? WHAT’S IMPORTANT FOR ME TO AVOID REPEATING?",
		"I WANT TO THANK MYSELF FOR ______.",
		"WHO INTIMIDATES ME? WHY DO THEY INTIMIDATE ME?",
		"WHAT HAVE I BEEN SENSITIVE TO LATELY?",
		"WHAT HAVE I ALWAYS ACCEPTED AS TRUE THAT TURNED OUT TO BE FALSE?",
		"WHAT'S ONE SMALL STEP I CAN TAKE TODAY TOWARDS A GOAL THAT FEELS OUT OF REACH?",
		"WHAT CAN I BE KINDER TOWARDS MYSELF FOR TODAY?",
		"WHAT AM I PUTTING OFF THAT FEELS IMPORTANT? WHY AM I AVOIDING IT?",
	]),
	createQuestionLevel("WILDCARDS", "wildcards", [
		"WILDCARD: WHAT AM I DOUBTING ABOUT MYSELF CURRENTLY? (REPLACE THAT THOUGHT WITH AN AFFIRMATION AND REPEAT IT OUT LOUD.)",
		"WILDCARD: LOOK IN THE MIRROR TODAY AND GIVE YOURSELF A GENUINE COMPLIMENT.",
		"WILDCARD: WHAT IS ONE TRUTH I'VE RECENTLY COME TO TERMS WITH? (WRITE IT OUT.)",
		"WILDCARD: WHAT'S MY FAVORITE SONG AT THE MOMENT? WHY DO I LOVE IT SO MUCH? (PLAY IT FOR YOURSELF.)",
		"WILDCARD: TAKE 3 DEEP BREATHS BEFORE ANSWERING THE NEXT QUESTION.",
		"WILDCARD: WHAT ARE 3 THINGS I LOVE MOST ABOUT MYSELF TODAY? (WRITE THEM OUT.)",
		"WILDCARD: WHAT DO I NEED TO GET OFF MY CHEST? (WRITE IT OUT.)",
		"WILDCARD: WHAT IS MY HAPPINESS LEVEL ON A SCALE OF 1-10? (WRITE IT DOWN AND DATE IT. WHY IS IT THIS NUMBER? WHAT WOULD MAKE IT 1 SCORE HIGHER?)",
		"WILDCARD: WHAT FEELS IMPOSSIBLE TODAY THAT WOULD SHOCK ME TO ACCOMPLISH IN A YEAR? (WRITE IT DOWN AND DATE IT.)",
		"WILDCARD: WHAT DO I NEED TO HEAR MOST RIGHT NOW? (WRITE IT OUT. PLACE SOMEWHERE YOU’LL SEE OFTEN.)",
	]),
];
const selfReflectionWnrsDeck = createNewDeck("SELF_REFLECTION_WNRS", "Self-Reflection Edition WNRS", selfReflectionWnrsQuestionLevels);


const internetPartOneQuestionLevels = [ 
	createQuestionLevel("ONE", "Level 1", [
			"Who is your favourite Disney villain?",
		    "What's your favorite story about something that happened to you?",
		    "What was your happiest moment in the last month?",
		    "What's been the most eventful moment of the past 6/12/18 months?",
		    "What are you most excited about that's coming up in your life?",
		    "What would be your death row dinner?",
		    "When was the last time you did something for the first time?",
		    "What’s something I’d never in a million years guess by looking at you?",
		    "What's something you could talk about endlessly?",
		    "Give me something from the top of your bucket list.",
		    "If you could travel anywhere tomorrow all expenses paid, where would you go?",
		    "If you could see one statistic floating over everyone’s head sims style what would it show?",
		    "Do you believe in the idea of soulmates?",
		    "If I asked your best friend what the worse thing about you is, what do you think they'd say?",
		    "How old were you when you had your first drink? What’s the story?",
			"Who are three of the most important people in your life?",
			"What are you watching on Netflix right now?",
			"Have you ever read a book that changed the way you thought?",
			"If you really like something on the menu, do you always order the same thing?",
			"What day in your life would you like to relive?",
			"What is the kindest act you have ever done?",
			"What was your dream job as a kid?",
			"What are the top three items on your bucket list? ",
			"Who would be your top 3 dream dinner guests?",
			"Would you rather speak 10 languages or play 10 instruments?",
			"If you could know the answer to any question, what would it be?",
			"If you could be a character in any movie, what character and what movie would it be?",
			"What is the worst grade you received for a class in school/college?",
			"Which two companies would you like to be sponsored by?",
			"Do you over or under-decorate for the holidays?",
			"Do you create a New Year’s resolution list?",
			"If you could live in a different country for a year, which country would you choose?",
			"What are you reading right now?",
			"Imagine it's the year 2050. What do you think will be the most significant change in society?",
			"What's the most unusual food combination you've ever tried and actually liked?",
			"Have you ever invented a secret recipe? If so, what's the ingredient that makes it unique?",
			"If you could have an animal adapted to be a size that can be kept as a pet, fearlessly loyal, and fully domesticated, what animal would it be?",
			"Is there a reality TV show you secretly want to be on? Why that one?",
			"Who is the best teacher you've ever had and why?",
			"If you had the power to alter any historical event, which one would you choose and why?",
			"If you could live in someone else's body for one day Freaky Friday style, who would you choose to swap with? Why?",
			"What social rules do you find completely unnecessary and would abolish altogether?",
			"If you could step into a machine that gives you more years to live, yet someone random loses years from their life, would you do it? How many years are you willing to add?",
			"If our life was a perpetual loop, at what point in your life would you want to be stuck?",
			"If we could transfer our consciousness to an artificial body, thus living indefinitely, would you choose to do so? Why or why not?",
			"If you had the ability to communicate with one species of animals, which species would you choose and why?",
			"If one day, the internet just disappeared, how do you think humanity would cope? How would it affect your life?",
			"If you could plant a memory into the mind of every person on Earth, what would the memory be?",
			"Have you ever considered the possibility that our perception of color may not be the same? How would you describe your favorite color to a blind person?",
			"If you could become invisible, but it would take one day off your life every time you did it, how often would you use this power?",
			"If you could witness a conversation between any two people from history, who would they be and what topic would you want them to discuss?",
			"If you could exchange one year of your life to master any skill, what skill would it be?",
			"If there were a public perception stock market, which person's stock would you buy, sell, or hold?",
			"If you were immersed in a video game world for one year, which game would you choose and why?",
			"If you were given an opportunity to relive a single day from your past just as a silent observer, which day would you choose and why?",
			"If you could choose one book or movie to erase from your memory so you could experience it again for the first time, which one would it be and why?",
			"If one day technology developed to a point where you could upload your consciousness to a virtual world after your demise, would you consider it? Why or why not?",

	    ]
	), 
	createQuestionLevel("TWO", "Level 2", [
		    "",
	    ]
	),
	createQuestionLevel("THREE", "Level 3", [
		    "",
	    ]
	),
];
const internetPartOneDeck = createNewDeck("INTERNET_PART_1", "Questions from the Internet Pt. 1", internetPartOneQuestionLevels);

const thirtySixQuestionLevels = [ 
	createQuestionLevel("ONE", "Set 1", [
		    "Given the choice of anyone in the world, whom would you want as a dinner guest?",
			"Would you like to be famous? In what way?",
			"Before making a telephone call, do you ever rehearse what you are going to say? Why?",
			"What would constitute a “perfect” day for you?",
			"When did you last sing to yourself? To someone else?",
			"If you were able to live to the age of 90 and retain either the mind or body of a 30-year-old for the last 60 years of your life, which would you want?",
			"Do you have a secret hunch about how you will die?",
			"Name three things you and your partner appear to have in common.",
			"For what in your life do you feel most grateful?",
			"If you could change anything about the way you were raised, what would it be?",
			"Take four minutes and tell your partner your life story in as much detail as possible.",
			"If you could wake up tomorrow having gained any one quality or ability, what would it be?",
	    ]
	), 
	createQuestionLevel("TWO", "Set 2", [
		    "If a crystal ball could tell you the truth about yourself, your life, the future or anything else, what would you want to know?",
			"Is there something that you’ve dreamed of doing for a long time? Why haven’t you done it?",
			"What is the greatest accomplishment of your life?",
			"What do you value most in a friendship?",
			"What is your most treasured memory?",
			"What is your most terrible memory?",
			"If you knew that in one year you would die suddenly, would you change anything about the way you are now living? Why?",
			"What does friendship mean to you?",
			"What roles do love and affection play in your life?",
			"Alternate sharing something you consider a positive characteristic of your partner. Share a total of five items.",
			"How close and warm is your family? Do you feel your childhood was happier than most other people’s?",
			"How do you feel about your relationship with your mother?",
	    ]
	),
	createQuestionLevel("THREE", "Set 3", [
		    "Make three true 'we' statements each. For instance, 'We are both in this room feeling ... '",
			"Complete this sentence: 'I wish I had someone with whom I could share ... '",
			"If you were going to become a close friend with your partner, please share what would be important for him or her to know.",
			"Tell your partner what you like about them; be very honest this time, saying things that you might not say to someone you’ve just met.",
			"Share with your partner an embarrassing moment in your life.",
			"When did you last cry in front of another person? By yourself?",
			"Tell your partner something that you like about them already.",
			"What, if anything, is too serious to be joked about?",
			"If you were to die this evening with no opportunity to communicate with anyone, what would you most regret not having told someone? Why haven’t you told them yet?",
			"Your house, containing everything you own, catches fire. After saving your loved ones and pets, you have time to safely make a final dash to save any one item. What would it be? Why?",
			"Of all the people in your family, whose death would you find most disturbing? Why?",
			"Share a personal problem and ask your partner’s advice on how he or she might handle it. Also, ask your partner to reflect back to you how you seem to be feeling about the problem you have chosen.",
	    ]
	),
];
const thirtySixDeck = createNewDeck("THIRTY_SIX", "36 Questions to Fall in Love", thirtySixQuestionLevels);

const threeHundredInterestingQuestionLevels = [ 
	createQuestionLevel("ONE", "Level 1", [
		    "What is the smallest thing for which you are grateful?",
			"Who has had the most positive impact on your life?",
			"If you could use a time machine, would you rather have one that only goes back in time or only goes forward?",
			"If you got a promotion, a job, a college acceptance, an accolade/award, or just generally accomplished something major, who is the first person you'd tell and how do you think they'd react?",
			"If you were an inanimate object, what would you be and why?",
			"Where do you wish you had grown up?",
			"If you could be good at any profession without having to receive the accompanying education or trade experience, which would you choose?",
			"What is something you're terrible at but wish you could do well?",
			"What is the quality you admire the most in the person you dislike the most?",
			"What was the most recent thing that made you cry?",
			"What are the books/movies/games that never get old and always make you feel better when you get down?",
			"What is the most trivial thing about which you have a strong opinion?",
			"If you could be any mythical creature, what would you be and why?",
			"If you’ve had more than one job, which job taught you the most?",
			"If you could change one thing about yourself physically, what would you change?",
			"What single event or decision do you think most affected the rest of your life? Was there a turning point in your life?",
			"What is the one thing you've made that you're the most proud of?",
			"Some historical figures have epithets attached to their names, like The Mad or The Wise. What would you like yours to be?",
			"What was the best year of your life? The worst?",
			"What one thing, whether it's something you did or something you made or something you caused to happen, would you like to be remembered by?",
			"What has been the biggest change of heart you've had? Have you ever started off on one side of an issue and wound up on the other? What influenced that change?",
			"What is your biggest non-academic, non work-related accomplishment?",
			"What is your biggest academic or work-related accomplishment?",
			"What is something you've done/felt/seen/etc. that you wish you could experience again for the first time?",
			"If you could erase one thing or event from your memory, what would you choose?",
			"Is there something about you that people assume because of your appearance or demeanour? What is a trait or preference you have that people don't expect you to have?",
			"If you were a character in a movie, book, or television show, what genre would you live in?",
			"What is the hardest way you’ve learned an important lesson?",
			"What cliché do you think is bullshit? What cliché do you think holds truth?",
			"What do you fear, despite having no real reason to do so? Basically, what is an irrational fear you have?",
			"Imagine that you could choose when and how you die. At what age would you like to die (no maximum here) and how would you like to go?",
			"What character archetype is closest to your personality?",
			"What was the happiest moment of your life so far?",
			"What is one childhood memory that you remember especially well?",
			"Is there a song/movie/food/etc. that strongly reminds you of someone whenever you experience it? Who does it remind you of?",
			"What is your ideal birthday? Not the date, but rather your ideal way to spend the day.",
			"Are there any smells that bring back memories for you? What are they, and what memories do they elicit?",
			"What is the closest you've ever come to dying?",
			"Do you have a personal mantra? If so, what is it?",
			"What is the most memorable meal you've ever had? (It can be good or bad.) What made it so memorable?",
			"What's the worst advice you've ever been given?",
			"In what ways do you benefit the world?",
			"What fact about yourself took you the longest to understand or accept?",
			"What is your least popular or most controversial opinion?",
			"If you could live in another time period but stay in the same place you live now, when would you want to live?",
			"Do you have any conspiracy theories? If so, what are they?",
			"What is the 'strangest' thing you believe in? Do you believe in the supernatural? Heaven, angels, ghosts? Luck, fate, magic? Mind reading, lizard people, the Illuminati? Where do you draw the line of belief and disbelief?",
			"What fictional character do you most relate to?",
			"What have you been meaning to do but haven't yet done? Why haven't you done it?",
			"Let's say that regardless of what job you have, you'll make $100,000 a year (£78,000; €90,000), enough to live comfortably without having to worry about expenses. What job would you choose to have if money were no object?",
			"What do you get the most compliments about?",
			"Have you ever had an epiphany? What was it about, and what sparked the realisation?",
			"What is the most important quality you look for in a friend? A romantic partner?",
			"What is a childhood habit or preference that you'll never outgrow?",
			"What is your favourite word and why?",
			"Which of your flaws are you most proud of?",
			"What is the most ordinary-seeming thing that you find fascinating?",
			"What lesson have you had to learn again and again?",
			"What was your favourite thing about the place you grew up?",
			"What scientific or technological advance blows your mind? Is there any technology that seems so futuristic and advanced you're surprised it actually exists?",
			"If you could ask your future self one thing, what would it be?",
			"What is something that gets cooler/more interesting the more you learn about it?",
			"What would you title your memoir?",
			"What was the hardest decision you've ever made?",
			"What word would your best friend use to describe you? Your parents? Your significant other (if applicable)?",
			"In what way would you most like to become famous?",
			"How would you summarise your life so far in one sentence?",
			"You're old and on your deathbed. With your last breath, you may deliver your last words to anyone you know. What do you say and who do say it to?",
			"If seasons never changed, which would you most like to live in eternally?",
			"What is the best advice you've ever received?",
			"You have one year to live: how do you spend it?",
			"Think of the best teacher you've ever had. What did they teach, and what made them so special?",
			"What is something you never thought you'd be able to do, until you actually did it?",
			"If you could go back and relive one day in your life without changing anything about it, which day would you revisit?",
			"If you had the power to completely eradicate one disease, which would you choose to abolish?",
			"If you had the power to completely solve one social or political issue affecting the world today, which would you choose?",
			"What was the scariest moment of your life thus far?",
			"If you lived in a colonial or Medieval village, what would your job be?",
			"Have you ever desired or longed for something, but once you got it, discovered it was overrated?",
			"What is something you've done that you recommend everyone do before they die?",
			"What do you know isn't real, but want badly for it to exist?",
			"What is the greatest physical pain you've ever felt?",
			"What is something you don't realise is weird until you really think about it?",
			"What's your favourite joke you've ever heard?",
			"Is there something you wish everybody knew about you without you having to tell them?",
			"What trait do you admire in others but don't possess yourself?",
			"What question do you most hate being asked?",
			"What is something other people admire about you?",
			"What's the best outcome of a complete accident you've experienced in your life?",
			"Have you ever gotten the giggles in an inappropriate place? Where was it?",
			"What's the best lesson you've learned from a difficult situation?",
			"What do you think 'success' means? How do you define it in your own life?",
			"What's your favourite article of clothing you own?",
			"What's something you wish you enjoyed but never can seem to?",
			"If you could play any instrument or learn any language in two hours, which would you choose and how would you use your talent?",
			"Have you ever challenged or questioned a belief you held for a long time? What prompted it and what was the result?",
			"What, if anything, is too serious to be joked about?",
			"What's the coolest project you've ever worked on?",
			"Are you currently pursuing what makes you happy? Why or why not?",
			"What did you know was a mistake as soon as you decided to do it, and why did you go through with it anyway?",
			"In what ways are you stubborn?",
			"What's a brief summary of your outlook on life?",
			"What are you most likely to be arrested for (even if you don't do anything illegal—what crime do you think you're most likely to commit)?",
			"Think of your biggest regret. If you could go back in time and make a decision to keep it from happening, would you?",
			"If you could be any animal, what would you be and why?",
			"What is the worst thing about the place you live?",
			"What is the best thing about the place you live?",
			"Does anybody beside yourself know your deepest secret? Do you ever intend to tell anyone?",
			"What is one small change you made in your life that had a much bigger impact that you anticipated?",
			"What historical event would you most like to have experienced?",
			"Do you believe in fate, the idea that 'whatever is meant to happen will happen'? Or do you believe more in a universe governed mainly by chaos and the unknown?",
			"What unpleasant experience are you most proud of yourself for making it through?",
			"In a 'Freaky Friday' situation where you woke up in someone else's body, who would you like to be for a day and have them inhabit your body?",
			"What's a quirk of yours that few people know about?",
			"What's the craziest thing you've ever done?",
			"Are you currently holding a grudge against anyone or anything?",
			"What small thing annoys you to an irrational degree?",
			"Have you gone through any phases in your life? What is the most notable one?",
			"What historical figure do you most admire and why?",
			"Was there ever a moment when you thought you were going to die?",
			"What was the best opportunity you ever received?",
			"What always makes you nostalgic?",
			"What would you like to accomplish before the year is over?",
			"Have you ever been offered a good opportunity but refused? What was the situation?",
			"Would you prefer your family to be smaller or larger than it is now, or neither? Do you wish you were more involved with your extended family?",
			"What is something you're hypocritical about; something you believe but have a hard time sticking to in practise?",
			"Has anyone close to you ever died? Was there anything that helped you to move past their death?",
			"What is something you're inherently bad at, no matter how hard you try to improve?",
			"Do you have any inherent talents?",
			"What small, insignificant thing gives you joy?",
			"If you could live anywhere in the world in any kind of domicile, and money was no object, where would you live and what would your dwelling be like?",
			"What is one thing you've done/seen/eaten/etc. that turned out to be really good, even though you initially didn't expect it to be?",
			"In the movie Inside Out, each person has a set of 'core memories' that define who they are and how they behave. What's one of yours?",
			"What is the biggest mistake you've made that actually turned out alright?",
			"What's the best decision you've ever made?",
			"When you're sick, what are some things you eat/drink/watch/etc. to be comfortable?",
			"What are some core principles you have that you'll never give up or change?",
			"Have you ever invented something? It doesn't have to be a traditional product either, it can be creating a new dish, coining a term.",
			"What cause do you strongly support, even though it wouldn't directly benefit you?",
			"What book, movie, film, or video game had the best ending?",
			"You have enough money to make a sizeable charitable donation—to which charity do you donate it and why? Or, if you choose not to go through a charity, how would you spend this money in a philanthropic way?",
			"What is something weird you do that you wonder if other people do as well?",
			"Suddenly you're in a horror film, running from a masked murderer. Your only companion is the last person you texted, and your only weapon is whatever is currently closest to your left hand. What is the movie like and do you think you could survive?",
			"What is one thing you're tired of telling/explaining to people?",
			"What embarrassing memory will forever be burned into your mind?",
			"What was the busiest day of your life thus far?",
			"What was the greatest day of your life thus far?",
			"What was the worst day of your life thus far?",
			"What is one thing you're good at that people don't expect?",
			"In what way do you differ most from your parents?",
			"In what way do you differ most from your best friend?",
			"What stereotype are you most like?",
			"What is one thing you're proud you haven't done?",
			"What is something that's difficult for you but easy for most other people?",
			"What is something that's easy for you but difficult for most other people?",
			"What are you glad you learned as a child?",
			"If you could know either when you die or how, which would you choose, if either?",
			"What is something you had to learn the hard way?",
			"What is something you want but will never have?",
			"If you could know for certain the answer to any question, what would that question be?",
			"What is a quote that speaks to you, and why do you like it?",
			"What advice do you wish you'd received earlier?",
			"What was the hardest time of your life, and how did you make it through?",
			"What is the best compliment you've ever received?",
			"What character trait instantly makes you respect someone?",
			"What is the best compliment you've ever given someone?",
			"What is a fear you've faced and how did you face it?",
			"What is an 'everyday evil' you experience often? Something banal but deeply unfair.",
			"What person you know do you look up to most and why?",
			"Has anyone ever saved your life? Have you ever saved someone else’s?",
			"What expensive thing is absolutely worth the money?",
			"If you could change your name (first, last, middle, or any combination of the three), what would you change it to and why?",
			"What historical figure would you most like to sit and have a conversation with over tea?",
			"Who do you wish was still alive?",
			"What is a story you always fall back on—one you've told so many times you nearly have it memorised?",
			"How has your year been going?",
			"There is an old, old man who lives deep in the forest. He is immortal, and was born in the year 0. You may ask him one question. What do you ask?",
			"How are you? No, really. How are you? Not 'Fine', not 'Good, thanks, and you?' How are you really? Now, today, at this moment?",
			"What were you severely underprepared for?",
			"What's the most selfish thing you've done?",
			"What's the most selfless thing you've done?",
			"If you could live in any fictional world, where would it be and why?",
			"What is your favourite question to answer?",
			"What is the biggest punishment you've ever received? Getting grounded, disciplinary file, prison?",
			"What about yourself is completely different from what you were like 10 years ago?",
			"What about yourself hasn't changed in the last 10 years?",
			"If you could erase one memory from your mind, what would it be and why?",
			"What wouldn't you do for someone you love?",
			"If you could enhance one of your senses twofold (e.g. your hearing would be twice as good as it is now), which would you choose to enhance?",
			"What's the best gift you've ever received? Who gave it to you?",
			"What's the best gift you've ever given? Who did you give it to?",
			"What one thing changed the way you see the world?",
			"If you died today, what would be your biggest regret?",
			"What is a completely rational, justified fear that you have?",
			"What is something you love that most people hate?",
			"What is something you hate that most people love?",
			"What do you wish was possible?",
			"Who was the last person you called, and what did you talk about?",
			"What will never cease to make you smile?",
			"What will always be funny to you?",
			"What is something that happened to you, despite the odds being stacked against you?",
			"If you were a superhero, what would be your name, costume, and catchphrase? Who would you fight?",
			"What was the last song that got stuck in your head?",
			"What is the longest or most complex thing you have memorised?",
			"Assume that god exists. For our purposes, he created the universe and has witnessed everything that has happened since. He also has control over everyone's lives and destinies. Once a millennium, he appears to somebody in his human form and will answer one question with absolute truth. He has chosen you. What do you ask him?",
			"If you could have any superpower for just one day, but you get to choose the superpower and when you activate it, what would you choose and when would you use it?",
			"In what way have you experienced the saying, 'You don't know what you have until you lose it'?",
			"Someone you love will die at the end of today. Only you know this; they don't. How do you spend your last day with them and when do you tell them that they're going to die, if ever?",
			"What do you wish you remembered better?",
			"Who is the single best person you have ever met? What makes them the best?",
			"What is something you know is going to happen, but hasn't yet?",
			"Have you ever gotten a 'hunch' about something that turned out to be true?",
			"What is the most dangerous thing you've ever done, and why did you do it?",
			"What do you think is worth dying for, if anything?",
			"Is there anything you have vowed never to do?",
			"Under what circumstances do you think you could kill someone, if any?",
			"What's your favourite memory with your best friend?",
			"What is a profession you admire but could never be a part of?",
			"What is something you enjoyed doing but would never do again?",
			"What object best defines who you are?",
			"What is the strongest emotional connection you've ever felt with a person? (It doesn't have to be romantic.)",
			"Do you have or have you ever had a nemesis?",
			"What’s the biggest fight you’ve gotten into, and what was it about?",
			"What would the ideal version of your life look like?",
			"What was the most recent thing that made you belly laugh?",
			"What is the dumbest purchase you’ve ever made?",
			"If you had to write your own eulogy to be read at your funeral, what would it say in brief?",
			"You have the opportunity to give a 10 minute speech which will be broadcast worldwide. What do you say?",
			"How long do you think you could survive in a remote forest with no tools?",
			"What person in your life would you most want to be stuck in an elevator with for 24 hours?",
			"What is the most obscure fact you know?",
			"Is there something you want to tell someone, but haven’t? Why haven’t you done it, and how do you think they would react if you told them?",
			"What’s a rant you’ve been holding in?",
			"What subject could you teach a college course on? What would be the title of the course, and what units would you cover?",
			"Have you ever had, a recurring dream or nightmare? What was it?",
			"What gives you hope for the future?",
			"What is something you fantasise about often?",
			"What do you find yourself thinking about just before you fall asleep?",
			"What was your favourite family tradition growing up? Have you created a tradition of your own?",
			"What is something you’re embarrassed to admit you enjoy? Why are you embarrassed about it?",
			"What if the first thing you hope people notice about you?",
			"When was the last time you did something nice for someone completely unprompted?",
			"What is a misconception people have about your profession?",
			"What do you want written on your headstone?",
			"What situation made you feel like a complete idiot?",
			"What outfit makes you feel invincible?",
			"Have you ever hit “rock bottom”? What did it look and feel like to you? What situations or decisions led to it? How did you get out, or have you gotten out yet?",
			"What current trend do you find troubling and why?",
			"What is the worst thing in your life that has resulted from a complete misunderstanding or miscommunication?",
			"What are you currently worrying about, if anything? What could happen to make you stop worrying about it?",
			"How do you think humanity will go extinct? When do you think it will happen?",
			"Is there a problem or situation for which you could use some advice?",
			"Has your gut feeling or first impression about something ever been wrong?",
			"What is your catchphrase, or something you find yourself saying often?",
			"At a party, are you the social butterfly who chats with everyone, the intense conversationalist who gets really deep with someone, the person who showed up for the snacks, the person getting drunk, the person dancing, the host, the person hanging around the edges and not talking much, or someone else?",
			"A stranger is inhabiting your body for the day. What are some tips you’d give them?",
			"Are you currently waiting for something? What are you waiting for? What are the best and worst possible scenarios?",
			"Is there someone you knew once but have lost touch with? What happened? Do you know where they are now, and how they’re doing?",
			"What is something you love, but can’t recommend to anyone you know because you know they won’t like it?",
			"Who was your childhood hero? Do you still look up to them? Why or why not?",
			"What’s something you’ve done so many times, you could do it with your eyes closed?",
			"What instantly makes you feel like a child again?",
			"What do you think happens after we die, if anything?",
			"Have you ever written a thank-you note to someone who created or helped create something you enjoy? If not, who would you write to and what would you thank them for?",
			"What is your perfect meal? Describe it—everything from the company to the setting to the music to, of course, the food.",
			"What are people not grateful enough for?",
			"What is the smallest thing that can ruin your day?",
			"Is there anyone you envy? What is it about them that you envy? If not, what is something people might envy you for?",
			"What is the most recent thing (skill, technique, theory, subject, etc.) you taught yourself?",
			"What is something you wish people cared less about? How do you think the world would be better if people cared less about it?",
			"What is something you wish people cared more about? How do you think the world would be better if people cared more about it?",
			"How do you feel about the future right now? Are you nervous, excited, unenthused?",
			"Have you ever cried in public? If so, was the reason?",
			"Do you have an inside joke with friends or family? How did it begin?",
			"What immediately makes you think less of someone?",
			"What immediately makes you think more highly of someone?",
			"Who could you listen to for hours? It can be someone you know or someone you don’t.",
			"What social custom do you wish would end and why?",
			"If a biopic were made of your life, which parts would you want to be left out and why?",
			"What were the longest five minutes you’ve ever experienced?",
			"Have you discovered anything about yourself in the past year?",
			"How would you want your children’s childhoods to be different from your own?",
			"What’s the quickest way to get you angry?",
			"Have you ever hidden something from your family or friends? How long did you hide it for, and why?",
			"How does the current generation of children differ from what children were like when you were younger?",
			"How would your life change if you inherited $100,000 tomorrow?",
			"What’s the strangest situation you’ve ever found yourself in?",
			"What is the best thing that happened to you this week?",
			"Do you have a nickname? If so, who gave it to you and how did it come about? If you could choose a nickname for yourself, what would you want it to be and why?",
			"What’s the most beautiful view you’ve ever seen?",
			"How did you and your best friend (or significant other, if applicable) meet?",
			"What are you looking forward to?",
			"What activity makes you lose track of time?",
			"What is the most useless talent or skill you possess? How and why did you learn it?",
			"If you opened a business, what type of business would it be? What would you name it?",
			"When you were a kid, were you excited to be a grownup? What about growing up excited you?",
			"Assume you have a lot of musical talent. If you started a band, how many people would be in it and which position would you be? What would you name it? What genre would you play?",
			"When was the last time you tried something new? What was it? Did you enjoy it?",
			"What’s something you went through that you wouldn’t wish on your worst enemy?",
			"When do you feel like the best version of yourself?",
			"What keeps you up at night?",
			"Has a piece of media (book, film, play, song, etc.) ever changed the way you thought about the world?",
			"What is a conspiracy theory you believe to be true?",
			"If you were a ghost tied to one location in the afterlife, where would you haunt?",
			"What are the stories behind your scars/tattoos, if you have any?",
	    ]
	), 
	createQuestionLevel("TWO", "Level 2", [
		    "",
	    ]
	),
	createQuestionLevel("THREE", "Level 3", [
		    "",
	    ]
	),
];
const threeHundredDeck = createNewDeck("THREE_HUNDRED", "300 Interesting Questions to Ask", threeHundredInterestingQuestionLevels);

const innerCircleQuestionLevels = [ 
	createQuestionLevel("ONE", "Level 1", [
		    "WHAT DO YOU THINK I’M MOST SENSITIVE TO?",
		    "WHAT DO YOU THINK MY MAIN LOVE LANGUAGE IS?",
	    ]
	), 
	createQuestionLevel("TWO", "Level 2", [
		    "WHAT’S THE HARDEST TRUTH YOU HAD TO FACE THIS PAST YEAR?",
		    "WHAT’S BROUGHT YOU THE MOST UNEXPECTED JOY RECENTLY?",
		    "WHO IN YOUR LIFE DO YOU FEEL YOU CAN BE MOST VULNERABLE WITH? EXPLAIN.",
		    "HAVE I EVER CHALLENGED YOUR WORLDVIEW? HOW SO?",
		    "WHAT IS A MOMENT IN OUR RELATIONSHIP THAT YOU FELT UNDENIABLY LOVED?",
		    "HOW CAN I ADD 1% MORE HAPPINESS TO YOUR LIFE?",
		    "A PERFECT DAY TOGETHER WOULD BE _____.",
		    "WHAT’S THE MOST FUN YOU CAN REMEMBER HAVING WITH ME RECENTLY?",
		    "HOW CAN I BEST SUPPORT YOU IN THIS CHAPTER IN YOUR LIFE?",
		    "WHAT’S A FEELING YOU’RE UNCOMFORTABLE SHARING WITH ME?",
		    "WHAT ARE YOU CURRENTLY WORKING THROUGH THAT I DON’T SEE?",
		    "WHEN’S THE LAST TIME YOU FELT LUCKY TO BE YOU?",
		    "WHEN’S THE LAST TIME I SURPRISED YOU?",
		    "WHEN WAS THE LAST TIME YOU FELT MOST YOURSELF?",
		    "FINISH THE SENTENCE: WHEN I’M HURT I _______. BOTH PLAYERS.",
	    ]
	),
	createQuestionLevel("THREE", "Level 3", [
		    "WHAT ARE YOU PROUDEST OF ME FOR?",
		    "WHAT’S SOMETHING WE SHOULD CELEBRATE TOGETHER?",
		    "YOU’VE SHOWN ME ______ ABOUT MYSELF.",
	    ]
	),
	createQuestionLevel("WILDCARDS", "Wilcards", [
		    "WILDCARD: SING YOUR FAVORITE SONG LYRICS YOU CAN THINK OF OFF THE TOP OF YOUR HEAD. BOTH PLAYERS.",
		    "WILDCARD: ASK ME SOMETHING YOU THINK IS OFF LIMITS. BOTH PLAYERS.",
		    "WILDCARD: DRAW YOUR FAVORITE MEMORY WITH ME. BOTH PLAYERS. COMPARE.",
		    "WILDCARD: SET AN INTENTION FOR THIS NEXT WEEK. STICK TO IT. (BOTH PLAYERS)",
		    "WILDCARD: FINISH THE SENTENCE: THANK YOU FOR _______. BOTH PLAYERS.",
	    ]
	),
];

const innerCircleWnrsDeck = createNewDeck("INNER_CIRCLE", "Inner Circle", innerCircleQuestionLevels);

const soundBitesSamplerLevels = [ 
	createQuestionLevel("ONE", "Personal File", [
		    "Speaking in complete sentences: Name three people who have made a difference in your life, and why.",
		    "Speaking in complete sentences: Name three modern inventions you don’t want to live without, and why.",
		    "Speaking in complete sentences: Name three chores you hate, and why.",
		    "Speaking in complete sentences: Name three fictional characters who you’d like to meet, and why.",
		    "Speaking in complete sentences: Name three occupations you would find fascinating, and why.",
		    "Share a personal story about: One of the best gifts you’ve ever received",
		    "Share a personal story about: A memorable plane, train, or bus ride",
		    "Share a personal story about: A time you surprised someone",
		    "Share a personal story about: One of your first trips away from home",
		    "Share a personal story about: A moment you’d love to relive",
		    "Describe how your life might change if: You could read a book just by holding it",
		    "Describe how your life might change if: You won the Nobel Prize",
		    "Describe how your life might change if: You could be in two places at once",
		    "Describe how your life might change if: You could be fluent in any language of your choice",
		    "Describe how your life might change if: You could be your boss’s boss for a day",
	    ]
	), 
	createQuestionLevel("TWO", "Straight Talk", [
		    "Give easy-to-follow directions on how to: Build a snowman",
		    "Give easy-to-follow directions on how to: Make a cup of tea",
		    "Give easy-to-follow directions on how to: Roll up a sleeping bag",
		    "Give easy-to-follow directions on how to: Play Tic-Tac-Toe",
		    "Give easy-to-follow directions on how to: Set an alarm clock",
		    "Give easy-to-follow directions on how to: Change a roll of toilet paper",
		    "Paraphrase the following words of wisdom: Those who sleep with dogs will rise with fleas.",
		    "Paraphrase the following words of wisdom: Necessity is the mother of invention.",
		    "Paraphrase the following words of wisdom: A chain is no stronger than its weakest link.",
		    "Paraphrase the following words of wisdom: You can catch more flies with honey than with vinegar.",
		    "Paraphrase the following words of wisdom: The pen is mightier than the sword",
		    "Explain in terms that a six-year-old would understand: Why we can’t have wolves as pets",
		    "Explain in terms that a six-year-old would understand: Why we see the stars only at night",
		    "Explain in terms that a six-year-old would understand: Why your mouth waters when you smell yummy food",
		    "Explain in terms that a six-year-old would understand: Why children look like their parents",
		    "Explain in terms that a six-year-old would understand: Why we pay taxes",
		    "Explain in terms that a six-year-old would understand: Why the earth has seasons",
	    ]
	),
	createQuestionLevel("THREE", "Justifications", [
		    "Give a compelling argument that: Lint is: 1)mischievous 2)coy 3)bold 4)beautiful 5)strong willed 6)mistaken. Choose a word at random.",
		    "Give a compelling argument that: Mismatched socks are: 1)primal 2)stealthy 3)contemplative 4)restless 5)tangy 6)mature. Choose a word at random.",
		    "Give a compelling argument that: A sunrise is: 1)hostile 2)confusing 3)polite 4)hardworking 5)ridiculous 6)expensive. Choose a word at random.",
		    "Give a compelling argument that: The Statue of Liberty is: 1)genuine 2)ditzy 3)delicious 4)secretive 5)efficient 6)stern. Choose a word at random.",
		    "Give a compelling argument that: Pro-wrestling is: 1)simple 2)creepy 3)intellectual 4)sticky 5)hilarious 6)mysterious. Choose a word at random.",
            "Provide a convincing definition for the following gibberish word: 1)Vestabular 2)Drefty 3)Axucate 4)Opulator 5)Feshful 6)Panadrill. Choose a word at random.",
            "Provide a convincing definition for the following gibberish word: 1)Bengustar 2)Querret 3)Thage 4)Endepulate 5)Dreendle 6)Smuftish. Choose a word at random.",
            "Provide a convincing definition for the following gibberish word: 1)Unbazzle 2)Pluckant 3)Olibert 4)Therian 5)Serebrian 6)Aclastic. Choose a word at random.",
            "Provide a convincing definition for the following gibberish word: 1)Spotacious 2)Pestorp 3)Exviation 4)Dabnick 5)Steffish 6)Gludal. Choose a word at random.",
            "Provide a convincing definition for the following gibberish word: 1)Glafick 2)Vonting 3)Strickish 4)Charnium 5)Mangular 6)Tacaltate. Choose a word at random.",
            "Offer a compelling argument to sell: A fishing rod to: 1)a nun 2)an archeologist 3)a Mafia boss 4)a glassblower 5)a safecracker 6)a janitor. Choose one at random.",
            "Offer a compelling argument to sell: A library card to: 1) a baby 2) a magician 3) a zookeeper 4) a janitor 5) a video game designer 6) a welder. Choose one at random.",
            "Offer a compelling argument to sell: A pair of tap shoes to: 1) a candy shop owner 2) a librarian 3) a little league soccer coach 4) a surgeon 5) a yoga teacher 6) a mail carrier. Choose one at random.",
            "Offer a compelling argument to sell: A ladder to: 1) a backpacker 2) a slam poet 3) a priest 4) a concert pianist 5) an airport security guard 6) a bug collector. Choose one at random.",
            "Offer a compelling argument to sell: A grand piano to: 1) a professional wrestler 2) a crossing guard 3) a police sketch artist 4) a lion tamer 5) a doctor 6) an NBA player. Choose one at random.",
	    ]
	),
	createQuestionLevel("FOUR", "Grace under Pressure", [
		    "Please offer tactful feedback for the following situation: Your boss, who continually pronounces the word fiscal as “fisical,” asks for your parting advice before her presentation to the board.",
		    "Please offer tactful feedback for the following situation: An attractive person sits near you at a café and strikes up a conversation. About 10 minutes into a really nice talk, you realize that he/she thinks you’re his/her blind date.",
		    "Please offer tactful feedback for the following situation: Even though she knows you are a vegetarian, your mother continues to sneak meat into your meals because, 'It's better for you.'",
		    "Please offer tactful feedback for the following situation: Your coworker in the next cubicle frequently interjects “humorous” comments into your phone conversations. He thinks he’s building camaraderie, but you’re feeling the opposite.",
		    "Please offer tactful feedback for the following situation: On a whim, your friend gets a tattoo of Japanese kanji characters that she believes say “Peace.” She wants to know what you think of it. You are fluent in Japanese and know it actually says something vulgar.",
		    "Please stand and deliver: A motivational speech to your fleet of eleven-year-old newspaper carriers",
		    "Please stand and deliver: A halftime pep talk to your high school football team when you’re down by 20 points",
		    "Please stand and deliver: An inspirational speech to baby birds on the eve of their first flight",
		    "Please stand and deliver: The best-man speech for your best friend’s third wedding",
		    "Please stand and deliver: A speech on the occasion of your own retirement",
		    "Use your powers of persuasion to convince someone in the room to: Babysit your kids and/or pets while you go on vacation",
		    "Use your powers of persuasion to convince someone in the room to: Contribute something homemade to your charity bake sale",
		    "Use your powers of persuasion to convince someone in the room to: Give up his or her phone for the day",
		    "Use your powers of persuasion to convince someone in the room to: Provide you with a wake-up call each morning next week",
		    "Use your powers of persuasion to convince someone in the room to: Swap offices (or desks) with you",
	    ]
	),
];

const speechSkillsSoundBitesSampler = createNewDeck("SPEECH_SKILLS_SOUND_BITES_SAMPLER", "SpeechSkills SoundBites Sampler", soundBitesSamplerLevels);

const soundBitesStraightTalkQuestionLevels = [ 
	createQuestionLevel("ONE", "Smart Than a First-Grader", [
		    "Explain in terms that a six-year-old would understand: Why the seasons change",
		    "Explain in terms that a six-year-old would understand: Why it takes time for your eyes to adjust to sudden darkness",
		    "Explain in terms that a six-year-old would understand: Why we don't live forever",
		    "Explain in terms that a six-year-old would understand: Why a ball rolls downhill",
		    "Explain in terms that a six-year-old would understand: Why we need to store food in the refrigerator",
		    "Explain in terms that a six-year-old would understand: Why it rains",
		    "Explain in terms that a six-year-old would understand: The theory of evolution",
		    "Explain in terms that a six-year-old would understand: Why we pay taxes",
		    "Explain in terms that a six-year-old would understand: Why we put money in banks",
		    "Explain in terms that a six-year-old would understand: What makes a rainbow", 
		    "Explain in terms that a six-year-old would understand: Why you breathe hard when you exercise",
		    "Explain in terms that a six-year-old would understand: Why your mouth waters when you smell yummy food",
		    "Explain in terms that a six-year-old would understand: Why we have scars",
		    "Explain in terms that a six-year-old would understand: How water gets to our faucets",
		    "Explain in terms that a six-year-old would understand: Why some animals hibernate",
		    "Explain in terms that a six-year-old would understand: Why the sun moves across the sky",
		    "Explain in terms that a six-year-old would understand: Why we find fish fossils on mountain tops",
		    "Explain in terms that a six-year-old would understand: Why we cook food",
		    "Explain in terms that a six-year-old would understand: Why we can't have wolves as pets",
		    "Explain in terms that a six-year-old would understand: Why we see the stars only at night",
		    "Explain in terms that a six-year-old would understand: Why the sun can burn our skin even on a cold day",
		    "Explain in terms that a six-year-old would understand: Why trees drop their leaves",
		    "Explain in terms that a six-year-old would understand: Why thunder follows a few seconds after the lightning flash",
		    "Explain in terms that a six-year-old would understand: Why we can't only eat candy",
		    "Explain in terms that a six-year-old would understand: Where paper comes from",
		    "Explain in terms that a six-year-old would understand: Why we don't all speak the same language",
		    "Explain in terms that a six-year-old would understand: Why children look like their parents",
		    "Explain in terms that a six-year-old would understand: Why are the dinosaurs are extinct",
		    "Explain in terms that a six-year-old would understand: How fish can breath underwater",
		    "Explain in terms that a six-year-old would understand: Why plants need sunshine",
	    ]
	), 
	createQuestionLevel("TWO", "In Other Words", [
		    "Paraphrase the following words of wisdom: All that glitters is not gold",
		    "Paraphrase the following words of wisdom: A chain is no stronger than its weakest link",
		    "Paraphrase the following words of wisdom: Don't count your chickens before they hatch",
		    "Paraphrase the following words of wisdom: If you can't stand the heat, get out of the kitchen",
		    "Paraphrase the following words of wisdom: You can catch more flies with honey than with vinegar",
		    "Paraphrase the following words of wisdom: You can lead a horse to water, but you can't make him drink",
		    "Paraphrase the following words of wisdom: A rose by any other name would smell just as sweet",
		    "Paraphrase the following words of wisdom: A bird in the hand is worth two in the bush",
		    "Paraphrase the following words of wisdom: If you want to make an omelette, you've got to break some eggs",
		    "Paraphrase the following words of wisdom: Can't see the forest for the trees", 
		    "Paraphrase the following words of wisdom: The pen is mightier than the sword",
		    "Paraphrase the following words of wisdom: The Apple doesn't fall far from the tree",
		    "Paraphrase the following words of wisdom: Blood is thicker than water",
		    "Paraphrase the following words of wisdom: Don't bite off more than you can chew",
		    "Paraphrase the following words of wisdom: Caught between a rock and a hard place",
		    "Paraphrase the following words of wisdom: Don't judge a book by its cover",
		    "Paraphrase the following words of wisdom: The grass is always greener on the other side of the fence",
		    "Paraphrase the following words of wisdom: A leopard cannot change its spots",
		    "Paraphrase the following words of wisdom: Necessity is the mother of invention",
		    "Paraphrase the following words of wisdom: Too many cooks spoil the broth",
		    "Paraphrase the following words of wisdom: Every cloud has a silver lining",
		    "Paraphrase the following words of wisdom: Out of the frying pan into the fire",
		    "Paraphrase the following words of wisdom: Don't look a gift horse in the mouth",
		    "Paraphrase the following words of wisdom: Strike while the iron is hot",
		    "Paraphrase the following words of wisdom: The bigger they are, the harder they fall",
		    "Paraphrase the following words of wisdom: You can't have your cake and eat it too",
		    "Paraphrase the following words of wisdom: Those who sleep with dogs will rise with fleas",
		    "Paraphrase the following words of wisdom: It takes two to tango",
		    "Paraphrase the following words of wisdom: Discretion is the better part of valor",
		    "Paraphrase the following words of wisdom: Idle hands are the devils tools",
	    ]
	),
	createQuestionLevel("THREE", "Instructions Not Included", [
		    "Give easy-to-follow directions on how to: make a PB&J sandwich",
		    "Give easy-to-follow directions on how to: make a bed",
		    "Give easy-to-follow directions on how to: wrap a gift",
		    "Give easy-to-follow directions on how to: wash a car",
		    "Give easy-to-follow directions on how to: replace a lightbulb",
		    "Give easy-to-follow directions on how to: scramble an egg",
		    "Give easy-to-follow directions on how to: put on a sweater",
		    "Give easy-to-follow directions on how to: merge onto the highway",
		    "Give easy-to-follow directions on how to: iron a shirt",
		    "Give easy-to-follow directions on how to: roll up a sleeping bag", 
		    "Give easy-to-follow directions on how to: make a cup of tea",
		    "Give easy-to-follow directions on how to: do laundry",
		    "Give easy-to-follow directions on how to: set a table",
		    "Give easy-to-follow directions on how to: fold a shirt",
		    "Give easy-to-follow directions on how to: sew on a button",
		    "Give easy-to-follow directions on how to: make mac & cheese from the box",
		    "Give easy-to-follow directions on how to: play tic-tac-toe",
		    "Give easy-to-follow directions on how to: set an alarm clock",
		    "Give easy-to-follow directions on how to: build a campfire",
		    "Give easy-to-follow directions on how to: shave your face or your legs",
		    "Give easy-to-follow directions on how to: hang a picture",
		    "Give easy-to-follow directions on how to: polish shoes",
		    "Give easy-to-follow directions on how to: put on a belt",
		    "Give easy-to-follow directions on how to: peel an orange",
		    "Give easy-to-follow directions on how to: make microwave popcorn",
		    "Give easy-to-follow directions on how to: Mail a letter",
		    "Give easy-to-follow directions on how to: build a snowman",
		    "Give easy-to-follow directions on how to: brush your teeth",
		    "Give easy-to-follow directions on how to: do a crossword puzzle",
		    "Give easy-to-follow directions on how to: replace the toilet paper roll",
	    ]
	),
];

const soundBitesStraightTalkDeck = createNewDeck("SPEECH_SKILLS_SOUND_BITES_STRAIGHT_TALK", "SpeechSkills SoundBites Straight Talk", soundBitesStraightTalkQuestionLevels);


const parentsAreHumanQuestionLevels = [ 
	createQuestionLevel("ONE", "Level 1", [
		    "Identity: What was your favorite food growing up?",
            "Identity: What is something that always brings you joy?",
            "Identity: What did you most enjoy doing as a child?",
            "Identity: What would you do if money was not a problem?",
            "Identity: What are you best at?",
            "Identity: If you could travel anywhere in the world, where would you go?",
            "Identity: If you won a million dollars, what would you do with the money?",
            "Life Events: Who was the most influential teacher you ever had?",
            "Life Events: What was the best news you have ever received?",
            "Life Events: What part of your life would you go back to relive? Why?",
            "Life Events: How is today's society different from when you were a child?",
            "Life Events: What is the most beautiful place you have ever seen?",
            "Life Events: What is the most embarrassing thing you have ever done?",
            "Life Events: What is your most precious memory?",
            "Life Events: What were you doing when you were my age?",
            "Life Events: What is the best thing someone has ever done for you?",
            "Wisdom: What is the best advice you have ever received?",
            "Wisdom: What movie has most inspired you? Why?",
            "Wisdom: What is something you like about our generation?",
            "Wisdom: What is the most impactful book you have ever read? Why?",
            "Wisdom: What kind of advice would you give to all parents?",
            "Relationships: Who has had the biggest impact on you and your life?",
            "Relationships: What is something you enjoy doing for me?",
            "Relationships: What have I done that has brought you joy?",
            "Relationships: Who has been your friend the longest? How did you meet this person?",
	    ]
	), 
	createQuestionLevel("TWO", "Level 2", [
		    "Identity: What are you most proud of in your life?",
			"Identity: What is one fear you would like to conquer?",
			"Identity: What do you love the most about yourself?",
			"Identity: What bad habit do you wish you could break?",
			"Identity: What do you feel is the purpose of your life?",
			"Life Events: What were the hardest choices you had to make in life?",
			"Life Events: What is your favorite memory of your parents?",
			"Life Events: What is the hardest job you have ever had?",
			"Life Events: What was your childhood home like?",
			"Life Events: What is the bravest thing you have ever done?",
			"Life Events: What is the most significant loss you have experienced?",
			"Life Events: How did you feel when I was born?",
			"Life Events: What is the scariest thing that has ever happened to you?",
			"Life Events: When was the last time you cried? Why?",
			"Wisdom: What advice would you give your past self?",
			"Wisdom: What was the most valuable lesson you learned from your parents?",
			"Wisdom: What is a healthy relationship?",
			"Wisdom: What are the most important lessons you want to pass on to the next generation?",
			"Wisdom: What would you write a book about?",
			"Wisdom: What is your philosophy for life?",
			"Relationships: What is something I do that makes you feel loved?",
			"Relationships: What is your biggest hope for me?",
			"Relationships: What have I done that has made you proud?",
			"Relationships: If you could restore one relationship, which one would you choose?",
			"Relationships:What do you wish I understood about you?",
	    ]
	),
	createQuestionLevel("THREE", "Action Cards", [
		    "Take a selfie together.",
			"Play three rounds of tic-tac-toe.",
			"Take turns to describe yourself in three words.",
			"Show off your best dance move.",
			"Take turns to describe each other in three words.",
			"Play three rounds of rock-paper-scissors.",
			"Share or describe your favorite picture of each other. ",
			"Give each other a warm hug.",
			"Take turns sharing three things that you are grateful for.",
			"Give each other a two-minute massage.",
			"Write down a list of three things you want to learn from each other, then exchange.",
			"Look into each other's eyes for a minute in silence.",
			"Kiss each other on the cheek.",
			"Call someone together and tell them a meaningful thank you.",
			"Hold hands for the duration of the next question.",
			"Take ten minutes to write a letter to each other, then exchange.",
			"Apologize to each other for something that you did wrong in the past.",
			"Take five minutes to write down some of the things you are most worried about, then exchange.",
			"Thank the other person for something they did for you.",
			"Show the other person you love them without using the word “love”.",
	    ]
	),
];

const parentsAreHumanDeck = createNewDeck("PARENTS_ARE_HUMAN", "Parents Are Human", parentsAreHumanQuestionLevels);

// const templateQuestionLevels = [ 
// 	createQuestionLevel("ONE", "Level 1", [
// 		    "",
// 	    ]
// 	), 
// 	createQuestionLevel("TWO", "Level 2", [
// 		    "",
// 	    ]
// 	),
// 	createQuestionLevel("THREE", "Level 3", [
// 		    "",
// 	    ]
// 	),
// ];

// const templateDeck = createNewDeck("UNIQUE_NAME", "My Unique Deck", templateQuestionLevels);

const decks = [mainWnrsDeck, honestDatingWnrsDeck, relationshipWnrsDeck, breakupWnrsDeck, selfReflectionWnrsDeck, internetPartOneDeck, thirtySixDeck, threeHundredDeck, innerCircleWnrsDeck, parentsAreHumanDeck, speechSkillsSoundBitesSampler, soundBitesStraightTalkDeck];

export {decks}